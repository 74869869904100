/** @format */
/**
 * # this the U65 stats data table components for agents, this table is not the same as the admin so it has to be separate.
 * # Author: Claude Lamarre
 * # frameWork: react.js
 * #libraries: redux, axios
 */

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { setUserProfile } from 'state/slice/buttonSlice';
import threeDot from 'img/DotsThreeOutlinehorizontal.svg';

const U65AgentStatsTable = () => {
  const startDate = useSelector((state) => state.dropDown.startDate);
  const endDate = useSelector((state) => state.dropDown.endDate);

  const [salesData, setSalesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const apiUrl = process.env.REACT_APP_SIADASH_BACKEND_URL; // Prefixed for Create React App

  useEffect(() => {
    const fetchSalesData = async () => {
      if (!startDate || !endDate) return;

      const token = localStorage.getItem('accessToken');

      try {
        console.log('Fetching data with dates:', startDate, endDate);
        const response = await axios.get(`${apiUrl}/api/stats/sales-table`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            startDate,
            endDate,
          },
        });
        console.log('API response:', response.data); // Log the response
        setSalesData(response.data);
        // console.log("Sales Data state:", salesData); // Log the state
      } catch (error) {
        console.error('Error fetching salesTable:', error);
        setError('Error fetching salesTable');
      } finally {
        setLoading(false);
      }
    };
    fetchSalesData();
  }, [startDate, endDate, apiUrl]);

  console.log('Sales Data state outside useEffect:', salesData); // Log outside useEffect

  const handleUserProfile = (Id) => {
    dispatch(setUserProfile({ userId: Id }));
  };

  // Function to calculate percentages
  const rate = (total, fraction) => {
    const percentage = (fraction / total) * 100;
    return Math.round(percentage);
  };

  const capitalizeFullName = (name) => {
    return name
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  if (loading) {
    return <p>Loading sales data...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }
  const sortedSalesData = [...salesData].sort(
    (a, b) =>
      b.totalPrivateMedPremium +
      b.totalAncillariesPremium -
      (a.totalPrivateMedPremium + a.totalAncillariesPremium)
  );
  return (
    <div>
      {salesData.length === 0 ? (
        <p>No data available for the selected date range.</p>
      ) : (
        <table
          className='data-table'
          style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              <th>#</th>
              <th>User</th>
              <th>Premium</th>
              <th>ACA</th>
              <th>U65</th>
              <th>CR</th>
              <th>EC</th>
            </tr>
          </thead>
          <tbody>
            {sortedSalesData.map((agent, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{capitalizeFullName(agent.agentName)}</td>

                <td>
                  {(
                    agent.totalAncillariesPremium + agent.totalPrivateMedPremium
                  )
                    .toFixed(2)
                    .toLocaleString()}
                </td>
                <td>{agent.totalMajorMedSales}</td>
                <td>{agent.totalPrivateMedSales}</td>
                <td
                  style={{
                    color:
                      rate(agent.totalBillableLeads, agent.totalSales) > 60
                        ? '#A1E3CB'
                        : '#FF000094',
                  }}>
                  {rate(agent.totalBillableLeads, agent.totalSales)}%
                </td>
                <td>
                  {(
                    agent.totalAncillariesPremium +
                    agent.totalPrivateMedPremium * 0.4
                  ).toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </td>
                <td>
                  <div
                    className='view-profile'
                    onClick={() => handleUserProfile(agent.userId)}>
                    <img src={threeDot} alt='three dot' />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default U65AgentStatsTable;
