/** @format */

import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Table, Button } from 'antd';
import { FullscreenOutlined, FullscreenExitOutlined } from '@ant-design/icons';

const SevenDayLeaderBoard = () => {
  const apiUrl = process.env.REACT_APP_SIADASH_BACKEND_URL;
  const [salesData, setSalesData] = useState([]);
  const [agentsData, setAgentsData] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [dailyTotals, setDailyTotals] = useState({
    totalMajorMed: Array(7).fill(0),
    totalPrivateMed: Array(7).fill(0),
    totalAncillaries: Array(7).fill(0),
    premiums: Array(7).fill(0),
  });

  // Initialize WebSocket connection
  useEffect(() => {
    const socket = io(apiUrl, {
      transports: ['websocket'], // Only use WebSockets
      query: {
        token: localStorage.getItem('accessToken'),
      },
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 2000,
      reconnectionDelayMax: 5000,
      timeout: 10000,
      autoConnect: true,
    });

    socket.on('connect', () => {
      const officeId = localStorage.getItem('officeId');
      if (officeId) {
        socket.emit('joinRoom', officeId);
        console.log('Socket connected', officeId);
      }
    });

    socket.on('leaderboardData', (data) => {
      setSalesData(data);
    });

    socket.on('disconnect', () => {
      console.log('Socket disconnected.');
    });

    return () => {
      socket.disconnect();
    };
  }, [apiUrl]);

  // Fetch sales data from the API
  useEffect(() => {
    const fetchSalesData = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/api/performance/overview/weekly-leaderboard-table`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          }
        );
        setSalesData(response.data);
      } catch (error) {
        console.error('Error fetching sales data:', error);
      }
    };

    fetchSalesData();
  }, [apiUrl]);

  // Process sales data for each agent
  useEffect(() => {
    const agentsMap = {};

    salesData.forEach((day) => {
      day.data.forEach((item) => {
        if (!agentsMap[item.agentName]) {
          agentsMap[item.agentName] = {
            name: item.agentName,
            totalMajorMed: Array(7).fill(0),
            totalPrivateMed: Array(7).fill(0),
            totalAncillaries: Array(7).fill(0),
            premiums: Array(7).fill(0),
            totalSales: 0,
            totalPremium: 0,
          };
        }

        const dayIndex = day.dayOfWeek;
        agentsMap[item.agentName].totalMajorMed[dayIndex] = item.totalMajorMed;
        agentsMap[item.agentName].totalPrivateMed[dayIndex] =
          item.totalPrivateMed;
        agentsMap[item.agentName].totalAncillaries[dayIndex] =
          item.totalAncillaries || 0; // Ensure the field is always present
        agentsMap[item.agentName].premiums[dayIndex] = item.totalPremium;
        agentsMap[item.agentName].totalSales +=
          item.totalMajorMed + item.totalPrivateMed;
        agentsMap[item.agentName].totalPremium += item.totalPremium;
      });
    });

    // Convert map to array and sort by totalPremium in descending order
    const sortedAgentsData = Object.values(agentsMap).sort(
      (a, b) => b.totalPremium - a.totalPremium
    );

    setAgentsData(sortedAgentsData);
  }, [salesData]);

  // Calculate the totals for each day
  useEffect(() => {
    const totals = {
      totalMajorMed: Array(7).fill(0),
      totalPrivateMed: Array(7).fill(0),
      totalAncillaries: Array(7).fill(0),
      premiums: Array(7).fill(0),
    };

    agentsData.forEach((agent) => {
      agent.totalMajorMed.forEach((value, index) => {
        totals.totalMajorMed[index] += value;
      });
      agent.totalPrivateMed.forEach((value, index) => {
        totals.totalPrivateMed[index] += value;
      });
      agent.totalAncillaries.forEach((value, index) => {
        totals.totalAncillaries[index] += value;
      });
      agent.premiums.forEach((value, index) => {
        totals.premiums[index] += value;
      });
    });

    setDailyTotals(totals);
  }, [agentsData]);

  const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  // Filter daysOfWeek to exclude Saturday and Sunday if no data
  const activeDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'];

  // Check if there is any data for Saturday and Sunday
  if (
    dailyTotals.totalMajorMed[5] > 0 ||
    dailyTotals.totalPrivateMed[5] > 0 ||
    dailyTotals.totalAncillaries[5] > 0
  ) {
    activeDays.push('Saturday');
  }

  if (
    dailyTotals.totalMajorMed[6] > 0 ||
    dailyTotals.totalPrivateMed[6] > 0 ||
    dailyTotals.totalAncillaries[6] > 0
  ) {
    activeDays.push('Sunday');
  }

  const columns = [
    {
      title: 'Agent',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      className: 'agent-column',
    },
    ...daysOfWeek.map((day, dayIndex) => ({
      title: day,
      children: [
        {
          title: 'ACA',
          dataIndex: ['totalMajorMed', dayIndex],
          key: `${day}-majormed`,
          render: (text) => text || 0,
          className: 'majormed-column',
        },
        {
          title: 'U65',
          dataIndex: ['totalPrivateMed', dayIndex],
          key: `${day}-privatemed`,
          render: (text) => text || 0,
          className: 'privatemed-column',
        },
        {
          title: 'Prem',
          dataIndex: ['premiums', dayIndex],
          key: `${day}-premiums`,
          render: (text) => `$${text ? text.toFixed(2) : '0.00'}`,
          className: 'premiums-column',
        },
      ],
    })),
    {
      title: 'Total',
      children: [
        {
          title: 'ACA',
          dataIndex: 'totalMajorMed',
          key: 'total-major-med',
          render: (totalMajorMed) =>
            totalMajorMed.reduce((acc, val) => acc + val, 0),
        },
        {
          title: 'U65',
          dataIndex: 'totalPrivateMed',
          key: 'total-private-med',
          render: (totalPrivateMed) =>
            totalPrivateMed.reduce((acc, val) => acc + val, 0),
          className: 'total-privatemed-column',
        },
        {
          title: 'Prem',
          dataIndex: 'totalPremium',
          key: 'total-premium',
          render: (totalPremium) => `$${totalPremium.toFixed(2)}`,
          className: 'total-premium-column',
        },
      ],
    },
  ];

  return (
    <div
      className={`leaderboard-container ${isExpanded ? 'expanded' : 'normal'}`}
      style={{
        position: isExpanded ? 'absolute' : 'relative',
        padding: '10px',
        top: '0px',
        left: '0px',
        height: isExpanded ? '100vh' : 'auto',
        width: isExpanded ? '100vw' : 'auto',
        overflow: isExpanded ? 'auto' : 'hidden',
        zIndex: isExpanded ? 9999 : 'auto',
        background: '#fff',
      }}>
      <Button
        type='primary'
        icon={isExpanded ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
        onClick={() => setIsExpanded(!isExpanded)}
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          zIndex: 10000,
        }}></Button>

      <Table
        dataSource={agentsData}
        columns={columns}
        rowKey={(record) => record.name}
        pagination={{ pageSize: 50 }}
        scroll={{ x: 'max-content' }}
        bordered
        className='leaderboard-table'
        summary={() => {
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                {daysOfWeek.map((day, dayIndex) => (
                  <React.Fragment key={day}>
                    <Table.Summary.Cell index={dayIndex * 3 + 1}>
                      {dailyTotals.totalMajorMed[dayIndex]}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={dayIndex * 3 + 2}>
                      {dailyTotals.totalPrivateMed[dayIndex]}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={dayIndex * 3 + 3}>
                      ${dailyTotals.premiums[dayIndex].toFixed(2)}
                    </Table.Summary.Cell>
                  </React.Fragment>
                ))}
                <Table.Summary.Cell index={21}>
                  {dailyTotals.totalMajorMed.reduce((acc, val) => acc + val, 0)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={22}>
                  {dailyTotals.totalPrivateMed.reduce(
                    (acc, val) => acc + val,
                    0
                  )}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={23}>
                  $
                  {dailyTotals.premiums
                    .reduce((acc, val) => acc + val, 0)
                    .toFixed(2)}
                </Table.Summary.Cell>
              </Table.Summary.Row>

              {/* New Average Premium Row */}
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>
                  Average Premium
                </Table.Summary.Cell>
                {daysOfWeek.map((day, dayIndex) => (
                  <React.Fragment key={day}>
                    <Table.Summary.Cell
                      index={dayIndex * 3 + 1}></Table.Summary.Cell>
                    <Table.Summary.Cell
                      index={dayIndex * 3 + 2}></Table.Summary.Cell>
                    <Table.Summary.Cell index={dayIndex * 3 + 3}>
                      {(() => {
                        const totalAncillaries =
                          dailyTotals.totalAncillaries[dayIndex];
                        console.log(totalAncillaries);
                        const totalPrivateMed =
                          dailyTotals.totalPrivateMed[dayIndex];
                        const totalSales = totalPrivateMed; // Total sales = MajorMed + PrivateMed
                        const totalPremiums = dailyTotals.premiums[dayIndex];
                        const averagePremium =
                          totalSales > 0 ? totalPremiums / totalSales : 0;
                        return `$${averagePremium.toFixed(2)}`;
                      })()}
                    </Table.Summary.Cell>
                  </React.Fragment>
                ))}
                <Table.Summary.Cell index={21}></Table.Summary.Cell>
                <Table.Summary.Cell index={22}></Table.Summary.Cell>
                <Table.Summary.Cell index={23}>
                  {(() => {
                    const totalPrivateMed = dailyTotals.totalPrivateMed.reduce(
                      (acc, val) => acc + val,
                      0
                    );
                    const totalAncillaries =
                      dailyTotals.totalAncillaries.reduce(
                        (acc, val) => acc + val,
                        0
                      );
                    console.log('total ancilarries', totalAncillaries);
                    const totalSales = totalPrivateMed + totalAncillaries; // Total sales = MajorMed + PrivateMed
                    const totalPremiums = dailyTotals.premiums.reduce(
                      (acc, val) => acc + val,
                      0
                    );
                    const averagePremium =
                      totalSales > 0 ? totalPremiums / totalSales : 0;
                    return `$${averagePremium.toFixed(2)}`;
                  })()}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    </div>
  );
};

export default SevenDayLeaderBoard;
